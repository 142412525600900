const ultraviolet_album = {
    "Dancing on Broken Glass": {
        "moods": ["Melancholic", "Hopeful", "Reflective"],
        "description": "Dancing on Broken Glass is a melancholic yet hopeful track that explores themes of resilience and finding beauty in pain. The reflective lyrics and poignant melody convey a sense of overcoming adversity.",
        "youtube_link": "https://youtu.be/FRANQHXnIfk?si=2xi3PVLURAVdiTtC"
    },
    "My Dark Disquiet": {
        "moods": ["Dark", "Intense", "Mysterious"],
        "description": "My Dark Disquiet is an intense and mysterious song that delves into the darker aspects of the human mind. The haunting melody and enigmatic lyrics create an atmosphere of intrigue and tension.",
        "youtube_link": "https://youtu.be/CkojH7htq-k?si=7zGeETfWxwoowwzj"
    },
    "False Kings": {
        "moods": ["Defiant", "Energetic", "Rebellious"],
        "description": "False Kings is a defiant and rebellious track that critiques the false idols and corrupt leaders in society. The energetic rhythm and powerful lyrics make it an anthem of resistance and empowerment.",
        "youtube_link": "https://youtu.be/KlHc92wbIx8?si=3fp1Zt-yYA6saENj"
    },
    "Fool's Paradise": {
        "moods": ["Cynical", "Melancholic", "Reflective"],
        "description": "Fool's Paradise is a cynical and melancholic song that reflects on the illusions and false promises of happiness. The reflective lyrics and somber melody evoke a sense of disillusionment and introspection.",
        "youtube_link": "https://youtu.be/m6x3zl4y23I?si=0cqA-TX-3E2U212B"
    },
    "Standstill": {
        "moods": ["Introspective", "Calm", "Thoughtful"],
        "description": "Standstill is an introspective and calm track that explores the moments of stillness and contemplation in life. The thoughtful lyrics and soothing melody create a peaceful and reflective atmosphere.",
        "youtube_link": "https://youtu.be/FsKxXnAoSDo?si=BO4tH2g9IxX60Pnr"
    },
    "The Sweet Escape": {
        "moods": ["Ethereal", "Dreamy", "Romantic"],
        "description": "The Sweet Escape is an ethereal and dreamy song that captures the essence of a romantic getaway. The delicate melody and poetic lyrics evoke a sense of tranquility and blissful love.",
        "youtube_link": "https://youtu.be/UnGn47ZU-FM?si=suXruFLX6kXeiSwQ"
    },
    "Moments Before the Storm": {
        "moods": ["Tense", "Dramatic", "Anticipatory"],
        "description": "Moments Before the Storm is a tense and dramatic track that builds anticipation for an impending emotional or physical event. The intense melody and evocative lyrics create a sense of urgency and foreboding.",
        "youtube_link": "https://youtu.be/ICOsK_-d9qg?si=-I5Jw7wsmjJsgKbg"
    },
    "In a Perfect World": {
        "moods": ["Hopeful", "Reflective", "Uplifting"],
        "description": "In a Perfect World is a hopeful and reflective song that envisions a better, idealized reality. The uplifting lyrics and optimistic melody inspire a sense of possibility and positive change.",
        "youtube_link": "https://youtu.be/tMuZSNbVKEo?si=cIgbt5ERws4MXZaD"
    },
    "Angel": {
        "moods": ["Romantic", "Tender", "Warm"],
        "description": "Angel is a romantic and tender ballad that expresses deep affection and admiration. The warm melody and heartfelt lyrics create a serene and loving atmosphere.",
        "youtube_link": "https://youtu.be/Ibv7yklrt7w?si=5ettvV_xhazJn7W7"
    },
    "Choir of Cicadas": {
        "moods": ["Nostalgic", "Ethereal", "Melancholic"],
        "description": "Choir of Cicadas is a nostalgic and ethereal track that captures the fleeting moments of summer nights and the passage of time. The melancholic lyrics and gentle melody evoke a sense of longing and reflection.",
        "youtube_link": "https://youtu.be/D48nq8sEJ94?si=9xydzyseibps8cbG"
    }
}
export default ultraviolet_album;