const temple_of_thought_album = {
    "Running Out of Time": {
        "moods": ["Urgent", "Energetic", "Intense"],
        "description": "Running Out of Time is an energetic and intense track that conveys a sense of urgency and the fleeting nature of time. The driving rhythm and passionate vocals emphasize the need to make the most of every moment.",
        "youtube_link": "https://youtu.be/yWyQCh2-P3k?si=y5ym3ZrC97q_os06"
    },
    "Temple of Thought": {
        "moods": ["Romantic", "Inspirational", "Hopeful"],
        "description": "Temple of Thought is the title track and serves as a romantic anthem filled with hope and inspiration. The lyrics speak of love's transformative power, and the uplifting melody adds to the song's emotional depth.",
        "youtube_link": "https://youtu.be/sK-CX04JAtQ?si=sfUjSwyGOjzoTzCF"
    },
    "Cradled in Love": {
        "moods": ["Tender", "Romantic", "Melancholic"],
        "description": "Cradled in Love is a tender and romantic ballad that captures the bittersweet nature of love and loss. The gentle melody and heartfelt lyrics create a melancholic yet comforting atmosphere.",
        "youtube_link": "https://youtu.be/1z0tX3dFI1s?si=Qn5JZxE8UItxgpoY"
    },
    "Kamikaze Love": {
        "moods": ["Passionate", "Intense", "Energetic"],
        "description": "Kamikaze Love is an intense and passionate song about love that defies all odds. The energetic beat and fervent lyrics capture the wild, all-consuming nature of a love that is both thrilling and dangerous.",
        "youtube_link": "https://youtu.be/AoJ1Hd7jK3U?si=c0y78ZKtO74nrapn"
    },
    "The Lie Eternal": {
        "moods": ["Dark", "Mysterious", "Brooding"],
        "description": "The Lie Eternal is a dark and brooding track that delves into themes of deception and hidden truths. The mysterious lyrics and haunting melody create an atmosphere of suspense and intrigue.",
        "youtube_link": "https://youtu.be/jq4TZ1JqLgk?si=PjOFUepw5h0n8yoi"
    },
    "Skin": {
        "moods": ["Sensual", "Intense", "Seductive"],
        "description": "Skin is a sensual and intense track that explores themes of physical and emotional connection. The seductive lyrics and powerful melody create a passionate and immersive experience.",
        "youtube_link": "https://youtu.be/KyPIKOLepj8?si=R_P8DIvPGyp5_a8n"
    },
    "The Distance": {
        "moods": ["Reflective", "Yearning", "Hopeful"],
        "description": "The Distance is a reflective and hopeful song about the challenges of love and the effort needed to overcome them. The lyrics express yearning and determination, with a melody that conveys both struggle and optimism.",
        "youtube_link": "https://youtu.be/5TJkUJDDQVQ?si=ojonXiJJrBG9si0I"
    },
    "Show Me This Life": {
        "moods": ["Energetic", "Uplifting", "Motivational"],
        "description": "Show Me This Life is an energetic and uplifting track that encourages embracing life's possibilities. The motivational lyrics and upbeat rhythm make it an anthem of empowerment and self-discovery.",
        "youtube_link": "https://youtu.be/kwhgAx51PYQ?si=LK_VD2DbtGiju8kK"
    },
    "Morning Tide": {
        "moods": ["Calm", "Serene", "Hopeful"],
        "description": "Morning Tide is a calm and serene song that symbolizes new beginnings and the promise of a new day. The soothing melody and hopeful lyrics create a peaceful and uplifting atmosphere.",
        "youtube_link": "https://youtu.be/XN4M-udTALM?si=cAiDKz55niMJL_uC"
    },
    "The Ballad of Jeremiah Peacekeeper": {
        "moods": ["Melancholic", "Reflective", "Storytelling"],
        "description": "The Ballad of Jeremiah Peacekeeper is a melancholic and reflective ballad that tells the story of a tragic hero. The storytelling lyrics and somber melody evoke a sense of loss and contemplation.",
        "youtube_link": "https://youtu.be/8d5oWanua7s?si=d2TUZ4DoU4dweZ2_"
    },
    "The Happy Song": {
        "moods": ["Upbeat", "Playful", "Energetic"],
        "description": "The Happy Song is an upbeat and playful track that contrasts with its darker lyrical content. The energetic rhythm and catchy melody create a sense of irony, making it a unique and intriguing song.",
        "youtube_link": "https://youtu.be/0y5bd-CgFz0?si=aEsgyOynkdsTV9yZ"
    }
}
export default temple_of_thought_album;