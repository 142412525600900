const jealous_gods_album = {
    "Daze": {
        "moods": ["Energetic", "Dreamy", "Uplifting"],
        "description": "Daze is an energetic and uplifting track with a dreamy atmosphere. The song explores themes of escapism and the search for meaning in a chaotic world, driven by a vibrant melody and anthemic chorus.",
        "youtube_link": "https://youtu.be/di7NMssrqsE?si=7P-CENbiTir6sRxD"
    },
    "Jealous Gods": {
        "moods": ["Intense", "Dark", "Cynical"],
        "description": "Jealous Gods, the title track, is an intense and dark exploration of power, control, and the destructive nature of envy. The cynical lyrics and brooding melody create a powerful and thought-provoking atmosphere.",
        "youtube_link": "https://youtu.be/4A0ptn6hf2Q?si=YHgbBNoV7rwbOr-A"
    },
    "Rumors": {
        "moods": ["Mysterious", "Seductive", "Tense"],
        "description": "Rumors is a mysterious and seductive track that delves into the impact of gossip and misinformation. The tense melody and alluring lyrics create an atmosphere of intrigue and suspicion.",
        "youtube_link": "https://youtu.be/qwhAsaxnm9U?si=gXHUupQer5JT2Z-_"
    },
    "Brighter Than the Sun": {
        "moods": ["Uplifting", "Inspirational", "Hopeful"],
        "description": "Brighter Than the Sun is an inspirational and uplifting song about finding strength and hope in the face of adversity. The soaring melody and positive lyrics create a sense of empowerment and optimism.",
        "youtube_link": "https://youtu.be/MYgX-OJXKMI?si=VkNC0s5BFzPNfPuS"
    },
    "Love Will Come to You": {
        "moods": ["Romantic", "Hopeful", "Tender"],
        "description": "Love Will Come to You is a tender and romantic ballad that offers hope and reassurance in the face of loneliness and heartache. The gentle melody and comforting lyrics create a warm, soothing atmosphere.",
        "youtube_link": "https://youtu.be/BwzgPnPSV6o?si=Ofh1epr9A4DCGT0q"
    },
    "Rogue": {
        "moods": ["Defiant", "Energetic", "Bold"],
        "description": "Rogue is a defiant and bold track that celebrates individuality and the courage to stand against the norm. The energetic rhythm and empowering lyrics make it an anthem for those who choose to forge their own path.",
        "youtube_link": "https://youtu.be/CMt5ZEmyfsA?si=WO61ZBT-sH7lS0SL"
    },
    "Rebirth": {
        "moods": ["Reflective", "Hopeful", "Uplifting"],
        "description": "Rebirth is a reflective and hopeful song about personal transformation and renewal. The uplifting melody and introspective lyrics create a sense of starting anew and finding peace within oneself.",
        "youtube_link": "https://youtu.be/We5fBmBC9_I?si=yFbxlQrBq7QVdh_U"
    },
    "Hounds to Hamartia": {
        "moods": ["Dark", "Intense", "Brooding"],
        "description": "Hounds to Hamartia is a dark and intense track that explores themes of downfall and self-destruction. The brooding melody and somber lyrics evoke a sense of inevitable tragedy.",
        "youtube_link": "https://youtu.be/-ypsron1rwM?si=a9_nkGdtqTZdSXXz"
    },
    "Clear Blue Sky": {
        "moods": ["Hopeful", "Uplifting", "Nostalgic"],
        "description": "Clear Blue Sky is an uplifting and hopeful song that reflects on the beauty of life and the clarity that comes with emotional breakthroughs. The nostalgic lyrics and bright melody evoke a sense of optimism and renewal.",
        "youtube_link": "https://youtu.be/e2m734fvOpo?si=0eb8L-Gdtu230wL_"
    },
    "Choice Millionaire": {
        "moods": ["Energetic", "Rebellious", "Bold"],
        "description": "Choice Millionaire is a bold and rebellious track that critiques materialism and the pursuit of wealth. The energetic rhythm and defiant lyrics make it a powerful statement against societal norms.",
        "youtube_link": "https://youtu.be/7q2v-TZEoDk?si=Ry9vvlgOl1efv4eu"
    },
    "Nothing Stays the Same": {
        "moods": ["Reflective", "Melancholic", "Hopeful"],
        "description": "Nothing Stays the Same is a reflective and melancholic song that deals with the inevitability of change and the passage of time. The hopeful undertones and bittersweet melody create a sense of acceptance and resilience.",
        "youtube_link": "https://youtu.be/4gvfgVAqLgA?si=1sYVHDVC0rqoQC58"
    }
}
export default jealous_gods_album; 