const signs_of_life_album = {
	"Lift": {
    	"moods": ["Uplifting", "Motivational", "Energetic"],
    	"description": "Lift is an energetic anthem about rising above challenges and embracing positivity. The dynamic melody and soaring vocals make it a perfect song for motivation and empowerment.",
    	"youtube_link": "https://youtu.be/1gi8zn2f9h8?si=ROVUhBkVksKVxELv"
	},
	"Overboard": {
    	"moods": ["Melancholic", "Reflective", "Introspective"],
    	"description": "A song with a haunting melody that explores themes of loneliness and emotional struggle, Overboard invites listeners to dive deep into their feelings and confront their inner battles.",
    	"youtube_link": "https://youtu.be/SMuJzEsqPrQ?si=6qIyCxz8YA7Krvu5"
	},
	"Late Goodbye": {
    	"moods": ["Nostalgic", "Reflective", "Bittersweet"],
    	"description": "Late Goodbye is a song about loss and saying goodbye, with a tone that is both melancholic and nostalgic. It evokes memories and emotions tied to farewells, making it a powerful and poignant track.",
    	"youtube_link": "https://youtu.be/sWrrqjYapbE?si=BpPHpJcSrH_VZGUS"
	},
	"Don't Mess with Me": {
    	"moods": ["Defiant", "Empowering", "Aggressive"],
    	"description": "With a gritty edge and assertive lyrics, Don't Mess with Me is a defiant anthem that exudes confidence and self-assurance. It's a song about standing up for oneself and not backing down.",
    	"youtube_link": "https://youtu.be/Q0z1YLe_RbI?si=amEmtq_duv05FUSc"
	},
	"3 AM": {
    	"moods": ["Contemplative", "Somber", "Lonely"],
    	"description": "3 AM captures the quiet, introspective mood of the early morning hours, where thoughts of love, loss, and longing come to the surface. The song is slow and haunting, perfect for late-night reflection.",
    	"youtube_link": "https://youtu.be/fEbZUFr0DNI?si=7Nr5cN-CbYT9KzTe"
	},
	"Stay": {
    	"moods": ["Romantic", "Yearning", "Tender"],
    	"description": "A heartfelt ballad, Stay expresses the longing to hold on to a moment or a person. The song's tender lyrics and gentle melody make it a romantic and emotional piece.",
    	"youtube_link": "https://youtu.be/5tpGGToAI3A?si=pu2YOHhglrKwGGH1"
	},
	"Seek You Out": {
    	"moods": ["Determined", "Hopeful", "Urgent"],
    	"description": "Seek You Out has a driving rhythm and an urgent tone, reflecting a relentless pursuit of something important, whether it's love, truth, or a personal goal. It's a song of determination and hope.",
    	"youtube_link": "https://youtu.be/CgXz6tCRUzE?si=H-R4f2-0D0dr4OLF"
	},
	"Shallow": {
    	"moods": ["Introspective", "Reflective", "Dark"],
    	"description": "Shallow delves into themes of superficiality and the emptiness that can come from it. The song's introspective lyrics and dark, brooding atmosphere encourage deep thought and self-reflection.",
    	"youtube_link": "https://youtu.be/Iwx4iQHbfdI?si=TLvZOPukZIFeC-AO"
	},
	"Everything Fades": {
    	"moods": ["Melancholic", "Pensive", "Somber"],
    	"description": "Everything Fades is a somber reflection on the impermanence of life and love. The mood is melancholic, with a haunting melody that underscores the fleeting nature of everything.",
    	"youtube_link": "https://youtu.be/9XEi9wR6rAU?si=QkVbxJHCGEQxh4PM"
	},
	"Someone Special": {
    	"moods": ["Romantic", "Uplifting", "Hopeful"],
    	"description": "This song is a celebration of love and the special connection between two people. It's uplifting and hopeful, with a warm, romantic vibe that makes it feel like a love letter in musical form.",
    	"youtube_link": "https://youtu.be/zJTzyMz8wpg?si=jHXKoxtx9whLry3u"
	},
	"Illusion & Dream": {
    	"moods": ["Philosophical", "Thought-Provoking", "Reflective"],
    	"description": "Illusion & Dream explores the complex nature of reality and perception. The lyrics are philosophical, prompting listeners to think deeply about the illusions in their own lives. The mood is reflective and introspective.",
    	"youtube_link": "https://youtu.be/VY8b_HuOOJY?si=n95pL0jHQ9ELXChu"
	},
	"Sleep": {
    	"moods": ["Calm", "Peaceful", "Tranquil"],
    	"description": "A soothing and calming song, Sleep is like a lullaby for the soul. It captures the peaceful feeling of drifting off to sleep, with a gentle melody and serene atmosphere.",
    	"youtube_link": "https://youtu.be/eDnzViN5chg?si=cX7ARa5dwy-veuCt"
	}
}
export default signs_of_life_album;