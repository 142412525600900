import carnival_of_rust_album from "../albums/carnival_of_rust_album";
import clearview_album from "../albums/clearview_album";
import jealous_gods_album from "../albums/jealous_gods_album";
import signs_of_life_album from "../albums/signs_of_life_album";
import temple_of_thought_album from "../albums/temple_of_thought_album";
import twilight_theater_album from "../albums/twilight_theater_album";
import revolution_roulette_album from "../albums/revolution_roulette_album";
import ghostlight_album from "../albums/ghostlight_album";
import ultraviolet_album from "../albums/ultraviolet_album";
import mood_song_map from "../albums/mood_song_map";

export const processMoodData = () => {
  const albums = [
    carnival_of_rust_album,
    clearview_album,
    jealous_gods_album,
    ghostlight_album,
    signs_of_life_album,
    revolution_roulette_album,
    temple_of_thought_album,
    twilight_theater_album,
    ultraviolet_album
  ];
  
  const moods = {};
  const titles = {};
  // Process album data
  albums.forEach((album) => {
    for (const title in album) {
      album[title].moods.forEach((mood) => {
        const lowerCaseMood = mood.toLowerCase();
        if (!(lowerCaseMood in moods)) {
          moods[lowerCaseMood] = {'titles': new Set(), 'songs': []};
        }

        if(!moods[lowerCaseMood]['titles'].has(title)){
            moods[lowerCaseMood]['songs'].push({
                title: title,
                description: album[title].description,
                youtube_link: album[title].youtube_link
            });
            moods[lowerCaseMood]['titles'].add(title);
        }

        titles[title] = album[title].youtube_link;
      });
    }
  });

  // Process mood_song_map data
  for (const mood in mood_song_map) {
    const lowerCaseMood = mood.toLowerCase();
    if (!(lowerCaseMood in moods)) {
      moods[lowerCaseMood] = {'titles': new Set(), 'songs': []}
    }

    mood_song_map[mood].forEach((song) => {
        if(!moods[lowerCaseMood]['titles'].has(song.title)){
            moods[lowerCaseMood]['titles'].add(song.title);
            moods[lowerCaseMood]['songs'].push({
                title: song.title,
                description: song.description,
                youtube_link: song.title in titles? titles[song.title] : song.video_link
            });
        }
    });
  }

  return moods;
};

export const getRandomSong = (songs) => {
  return songs[Math.floor(Math.random() * songs.length)];
};