import React, { useState, useEffect, useRef } from 'react';
import { 
  AppBar, Toolbar, Container, Typography, Card, CardContent, 
  Box, Pagination, CssBaseline, ThemeProvider, SvgIcon,
  Tabs, Tab, ListItemButton, ListItemText, ListItemIcon, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { processMoodData } from '../../utils/moodUtils';
import { darkTheme } from '../../themes/darkTheme';
import VideoPlayer from './VideoPlayer';
import MoodSelector from './MoodSelector';

const SONGS_PER_PAGE = 3;

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#000000',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const GradientHeartIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <linearGradient id="heartGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#d2ba70" />
          <stop offset="100%" stopColor="#574924" />
        </linearGradient>
      </defs>
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" fill="url(#heartGradient)"/>
    </svg>
  </SvgIcon>
);

const FixedTopContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: theme.palette.background.default,
}));

const ScrollableListContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '1em', 
  margin: '0.5em',
  height: 'var(--scrollable-height)',
}));

const FixedBottom = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.grey[800]}`,
  zIndex: 1000,
}));

const MoodSelectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 -4px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  borderTop: `1px solid ${theme.palette.grey[800]}`,
  borderBottom: `1px solid ${theme.palette.grey[800]}`,
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontSize: '0.9rem',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, isPlaying }) => ({
  backgroundColor: isPlaying ? theme.palette.grey[900] : 'transparent',
  '&:hover': {
    backgroundColor: isPlaying ? theme.palette.grey[900] : theme.palette.action.hover,
  },
  padding: 0,
  marginLeft: '-1em',
  marginRight: '-1em'
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.palette.primary.light,
    fontSize: '1rem',
    padding: '1em',
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
  },
  '& .MuiListItemText-secondary': {
    fontSize: '0.9rem',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingBottom: '1em'
  },
}));

const RandomSongButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

// Helper functions
const getYouTubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

const getRandomSong = (songs) => {
  return songs[Math.floor(Math.random() * songs.length)];
};

const MoodMatcher = () => {
  const [moods, setMoods] = useState([]);
  const [moodData, setMoodData] = useState(null);
  const [selectedMood, setSelectedMood] = useState('');
  const [matchedSongs, setMatchedSongs] = useState([]);
  const [randomSong, setRandomSong] = useState(null);
  const [error, setError] = useState('');
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [scrollableHeight, setScrollableHeight] = useState('100vh');
  const fixedTopRef = useRef(null);

  useEffect(() => {
    const initializeMoodData = () => {
      const processedMoodData = processMoodData();
      setMoodData(processedMoodData);
      const moodList = Object.keys(processedMoodData)
        .sort((a, b) => a.localeCompare(b))
        .map((mood) => ({ name: mood, id: mood }));
      setMoods(moodList);
      
      if (moodList.length > 0) {
        const defaultMood = moodList[0].id;
        setSelectedMood(defaultMood);
        updateSongsForMood(defaultMood, processedMoodData);
      }
    };

    initializeMoodData();

    // Calculate scrollable height
    const updateScrollableHeight = () => {
      if (fixedTopRef.current) {
        const fixedTopHeight = fixedTopRef.current.offsetHeight;
        const newScrollableHeight = `calc(100vh - ${fixedTopHeight}px)`;
        setScrollableHeight(newScrollableHeight);
        document.documentElement.style.setProperty('--scrollable-height', newScrollableHeight);
      }
    };

    updateScrollableHeight();
    window.addEventListener('resize', updateScrollableHeight);

    return () => {
      window.removeEventListener('resize', updateScrollableHeight);
    };
  }, []);

  const updateSongsForMood = (moodId, data = moodData) => {
    if (data && data[moodId] && data[moodId].songs.length > 0) {
      const songs = data[moodId].songs;
      setMatchedSongs(songs);
      setTotalPages(Math.ceil(songs.length / SONGS_PER_PAGE));
      setRandomSong(getRandomSong(songs));
      setCurrentVideoId(getYouTubeId(songs[0].youtube_link));
    } else {
      setMatchedSongs([]);
      setRandomSong(null);
      setTotalPages(1);
      setCurrentVideoId('');
      setError('No songs found for this mood.');
    }
  };

  const handleMoodSelect = (event) => {
    const moodId = event.target.value;
    setSelectedMood(moodId);
    setError('');
    setPage(1);
    setTabValue(0);
    updateSongsForMood(moodId);
  };

  const handleVideoSelect = (videoLink) => {
    const videoId = getYouTubeId(videoLink);
    setCurrentVideoId(videoId);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      setRandomSong(getRandomSong(matchedSongs));
    }
  };

  const handleNewRandomSong = () => {
    setRandomSong(getRandomSong(matchedSongs));
  };

  const makeSongTitle = (title) => {
    return (
      <span>
        <ArrowForwardIcon sx={{ color: 'primary.light', mr: 1, mt: -1, mb: -1 }} />
        {title}
      </span>
    );
  }

  const paginatedSongs = matchedSongs.slice((page - 1) * SONGS_PER_PAGE, page * SONGS_PER_PAGE);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <FixedTopContainer ref={fixedTopRef}>
          <StyledAppBar position="static">
            <Toolbar variant="dense">
              <GradientHeartIcon sx={{ mr: 1, fontSize: 32 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#d2ba70' }}>
                Poets of the Fall Mood Matcher
              </Typography>
            </Toolbar>
          </StyledAppBar>
          {currentVideoId && <VideoPlayer videoId={currentVideoId} />}
          <MoodSelectionContainer>
            <MoodSelector moods={moods} selectedMood={selectedMood} onMoodSelect={handleMoodSelect} />
          </MoodSelectionContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="song view tabs">
              <Tab label={"All Songs (" + matchedSongs.length + ")"} />
              <Tab label="Random Song" />
            </Tabs>
          </Box>
        </FixedTopContainer>
        <ScrollableListContainer>
          {tabValue === 0 ? (
            matchedSongs.length > 0 && (
              <Card>
                <CardContent sx={{"paddingTop": 0}}>
                  {paginatedSongs.map((song, index) => (
                    <StyledListItemButton
                      key={index}
                      onClick={() => handleVideoSelect(song.youtube_link)}
                      isPlaying={getYouTubeId(song.youtube_link) === currentVideoId}
                    >
                      <StyledListItemText
                        primary={makeSongTitle(song.title)}
                        secondary={song.description}
                      />
                    </StyledListItemButton>
                  ))}
                </CardContent>
              </Card>
            )
          ) : (
            randomSong && (
              <Card>
                <CardContent sx={{"paddingTop": 0, "paddingBottom": 0}}>
                  <StyledListItemButton
                    onClick={() => handleVideoSelect(randomSong.youtube_link)}
                    isPlaying={getYouTubeId(randomSong.youtube_link) === currentVideoId}
                  >
                    <StyledListItemText
                      primary={makeSongTitle(randomSong.title)}
                      secondary={randomSong.description}
                    />
                  </StyledListItemButton>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <RandomSongButton
                      variant="contained"
                      startIcon={<ShuffleIcon />}
                      onClick={handleNewRandomSong}
                    >
                      New Random Song
                    </RandomSongButton>
                  </Box>
                </CardContent>
              </Card>
            )
          )}
        </ScrollableListContainer>
        {tabValue === 0 && matchedSongs.length > SONGS_PER_PAGE && (
          <FixedBottom>
            <StyledPagination 
              count={totalPages} 
              page={page} 
              onChange={handlePageChange} 
              color="primary" 
              sx={{ display: 'flex', justifyContent: 'center' }}
            />
          </FixedBottom>
        )}
        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default MoodMatcher;