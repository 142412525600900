import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#aa9248', // Gold color
      light: '#d2ba70', // Lighter gold for titles
    },
    secondary: {
      main: '#E6CF8B',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    grey: {
      800: '#424242', // Darker shade of grey for borders
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h6: {
      fontSize: '0.9rem',
      textTransform: 'lowercase',
      fontVariant: 'small-caps',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000000', // Black text for better contrast on gold background
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#CFB53B', // Gold for selected pagination item
            color: '#000000', // Black text for better contrast
            '&:hover': {
              backgroundColor: '#E6CF8B', // Slightly lighter gold on hover
            },
          },
        },
      },
    },
  },
});