const carnival_of_rust_album = {
	"Fire": {
    	"moods": ["Passionate", "Energetic", "Intense"],
    	"description": "Fire is a powerful and passionate song that bursts with energy. The lyrics and driving rhythm create an intense atmosphere, making it a fiery anthem of desire and determination.",
    	"youtube_link": "https://youtu.be/oXi2hkU0EaU?si=euCJujDsyUk2hzaQ"
	},
	"Sorry Go 'Round": {
    	"moods": ["Reflective", "Melancholic", "Bittersweet"],
    	"description": "Sorry Go 'Round is a contemplative track about the cyclical nature of regret and forgiveness. The song carries a bittersweet tone, with reflective lyrics that delve into emotional struggles.",
    	"youtube_link": "https://youtu.be/mzDB5VHy0ME?si=LZZD0mZDmrQ3NHBw"
	},
	"Carnival of Rust": {
    	"moods": ["Melancholic", "Nostalgic", "Dark"],
    	"description": "Carnival of Rust is the title track and one of the band's most iconic songs. It evokes a sense of melancholy and nostalgia, with dark and haunting melodies that explore the decay of innocence and the passage of time.",
    	"youtube_link": "https://youtu.be/MKk1u5RMTn4?si=LfGyYWbOF9LROOda"
	},
	"King of Fools": {
    	"moods": ["Defiant", "Empowering", "Cynical"],
    	"description": "King of Fools is a defiant and cynical song about challenging authority and questioning societal norms. The empowering lyrics and bold melody create an anthem for standing up against oppression.",
    	"youtube_link": "https://youtu.be/rFq_XatLsiw?si=9udwrNyIblfpIinC"
	},
	"Locking Up the Sun": {
    	"moods": ["Energetic", "Defiant", "Empowering"],
    	"description": "Locking Up the Sun is an energetic and defiant track about seizing control and challenging the status quo. The powerful rhythm and strong vocals make it an anthem of empowerment.",
    	"youtube_link": "https://youtu.be/V9vHb2WRlwI?si=KDXUXKx0NPUZoDC6"
	},
	"Gravity": {
    	"moods": ["Reflective", "Philosophical", "Introspective"],
    	"description": "Gravity explores the metaphorical weight of life's challenges. It's a reflective and introspective song with philosophical lyrics that question the forces that hold us down.",
    	"youtube_link": "https://youtu.be/L1hIuXdFIuU?si=f1pYYnsAhiN8bD3C"
	},
	"Roses": {
    	"moods": ["Romantic", "Bittersweet", "Nostalgic"],
    	"description": "Roses is a romantic yet bittersweet ballad that reflects on the beauty and fragility of love. The song's gentle melody and heartfelt lyrics create a nostalgic atmosphere.",
    	"youtube_link": "https://youtu.be/UdNiO3MY0wE?si=lXeFuH9cG6nH2Pjz"
	},
	"Desire": {
    	"moods": ["Passionate", "Seductive", "Intense"],
    	"description": "Desire is an intense and seductive track that captures the fervent emotion of longing and attraction. The passionate lyrics and sultry melody make it a song of deep desire.",
    	"youtube_link": "https://youtu.be/SJ1i788-gL4?si=q7cVBzt1VjPTQA9N"
	},
	"All the Way / 4U": {
    	"moods": ["Romantic", "Tender", "Yearning"],
    	"description": "All the Way / 4U is a tender love song that expresses deep devotion and commitment. The romantic lyrics and soft melody convey a sense of yearning and heartfelt affection.",
    	"youtube_link": "https://youtu.be/zBKwNrhL_uI?si=NX1iZaBe1AVUPpHp"
	},
	"Delicious": {
    	"moods": ["Playful", "Energetic", "Flirtatious"],
    	"description": "Delicious is a playful and flirtatious track with an upbeat tempo and catchy melody. The lyrics have a fun and cheeky tone, making it an energetic and lively song.",
    	"youtube_link": "https://youtu.be/rxvKkDA_dnc?si=igxOFVM2i1ViFMaN"
	},
	"Maybe Tomorrow is a Better Day": {
    	"moods": ["Hopeful", "Reflective", "Melancholic"],
    	"description": "Maybe Tomorrow is a Better Day is a reflective song that balances melancholy with a sense of hope. The lyrics suggest that despite difficulties, there's always the possibility of a brighter future.",
    	"youtube_link": "https://youtu.be/8sylWlivzvU?si=byQGMY61q-kS7DlN"
	},
	"Dawn": {
    	"moods": ["Hopeful", "Calm", "Tranquil"],
    	"description": "Dawn is a calm and tranquil song that symbolizes new beginnings. The peaceful melody and soothing lyrics create an atmosphere of serenity and hope, much like the quiet beauty of a new day.",
    	"youtube_link": "https://youtu.be/AvLwDJeE29U?si=l9mUKW_zVrmmcVhR"
	}
}
export default carnival_of_rust_album;