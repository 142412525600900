const ghostlight_album = {
    "Firedancer": {
        "moods": ["Energetic", "Intense", "Defiant"],
        "description": "Firedancer is an energetic and intense track, driven by powerful rhythms and defiant lyrics. The song embodies the idea of dancing through life's challenges with fiery determination.",
        "youtube_link": "https://youtu.be/m8ZW4xBavZ0?si=OhGzgGkN4r_ZhnN3"
    },
    "Requiem for My Harlequin": {
        "moods": ["Melancholic", "Theatrical", "Reflective"],
        "description": "Requiem for My Harlequin is a melancholic and theatrical track that reflects on the duality of life and the masks we wear. The reflective lyrics and dramatic composition evoke a sense of nostalgia and introspection.",
        "youtube_link": "https://youtu.be/0XNf74SCMy4?si=fVi3bouF7BAP2Upq"
    },
    "Sounds of Yesterday": {
        "moods": ["Nostalgic", "Melancholic", "Poignant"],
        "description": "Sounds of Yesterday is a nostalgic and melancholic song that reminisces about the past. The poignant lyrics and gentle melody create a longing for what once was, capturing the essence of fleeting memories.",
        "youtube_link": "https://youtu.be/OW0p2v6-BPU?si=hBNAJZNC58Rp-8We"
    },
    "Revelations": {
        "moods": ["Epic", "Dramatic", "Uplifting"],
        "description": "Revelations is an epic and dramatic track that explores themes of self-discovery and truth. The uplifting melody and powerful lyrics inspire a sense of awakening and realization.",
        "youtube_link": "https://youtu.be/VR-30wN4i0E?si=ULW-XuX150zV8X-K"
    },
    "Heroes and Villains": {
        "moods": ["Intense", "Reflective", "Cinematic"],
        "description": "Heroes and Villains is an intense and cinematic track that delves into the complexities of morality. The reflective lyrics and dynamic composition portray the blurred lines between good and evil.",
        "youtube_link": "https://youtu.be/8pQ_DBpgYNE?si=-FQ5qMoWwKoTEln7"
    },
    "Lust for Life": {
        "moods": ["Energetic", "Rebellious", "Optimistic"],
        "description": "Lust for Life is an energetic and rebellious anthem that celebrates living life to the fullest. The optimistic lyrics and driving melody encourage embracing every moment with passion and joy.",
        "youtube_link": "https://youtu.be/xrhm2rQtWTk?si=IKXeuPz-O8jPFYP4"
    },
    "Chasing Echoes": {
        "moods": ["Haunting", "Melancholic", "Dreamy"],
        "description": "Chasing Echoes is a haunting and melancholic track that explores the theme of searching for something lost. The dreamy melody and echoing vocals create an atmosphere of yearning and introspection.",
        "youtube_link": "https://youtu.be/xFXl4yB00FU?si=c5mEdpHzensno4an"
    },
    "Weaver of Dreams": {
        "moods": ["Ethereal", "Reflective", "Mystical"],
        "description": "Weaver of Dreams is an ethereal and mystical song that transports listeners to a world of fantasy and imagination. The reflective lyrics and soft melody evoke a sense of wonder and introspection.",
        "youtube_link": "https://youtu.be/s87C01Nobkg?si=nRPFUk52LfA_rYHP"
    },
    "Hello Cabaret": {
        "moods": ["Theatrical", "Playful", "Mysterious"],
        "description": "Hello Cabaret is a theatrical and playful track that brings the atmosphere of a cabaret to life. The mysterious lyrics and lively melody create a sense of intrigue and entertainment.",
        "youtube_link": "https://youtu.be/j2FWOCd0Rf4?si=jrAuoZSCPUFXCrml"
    },
    "Beyond the Horizon": {
        "moods": ["Uplifting", "Epic", "Hopeful"],
        "description": "Beyond the Horizon is an uplifting and epic finale to the album, exploring the idea of what lies beyond our current understanding. The hopeful lyrics and grand melody inspire a sense of adventure and optimism.",
        "youtube_link": "https://youtu.be/y0-t9yntElA?si=X0FMRJpHRF6aBS_W"
    }
}
export default ghostlight_album;