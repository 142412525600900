const revolution_roulette_album = {
    "More": {
        "moods": ["Intense", "Desperate", "Reflective"],
        "description": "More is an intense track that explores the insatiable human desire for more, whether it be material wealth, power, or emotional fulfillment. The lyrics are reflective, questioning the cost of this endless pursuit.",
        "youtube_link": "https://youtu.be/x25UBZqrl7w?si=nZPLCwLfqDm0B1Hp"
    },
    "The Ultimate Fling": {
        "moods": ["Energetic", "Rebellious", "Empowering"],
        "description": "The Ultimate Fling is a rebellious anthem about living life on the edge and embracing the thrill of taking risks. The song's energetic rhythm and empowering lyrics make it a celebration of freedom and defiance.",
        "youtube_link": "https://youtu.be/y3rYhyIk8jM?si=KHwgngESVr0w1Dk8"
    },
    "Revolution Roulette": {
        "moods": ["Cynical", "Intense", "Dark"],
        "description": "Revolution Roulette is the title track and captures the dark and cynical tone of the album. The song addresses the chaos and unpredictability of life, comparing it to a game of chance. The intense lyrics and heavy melody reflect the theme of societal and personal upheaval.",
        "youtube_link": "https://youtu.be/y3rYhyIk8jM?si=yB2kmcxj0CZ7S4O0"
    },
    "Psychosis": {
        "moods": ["Dark", "Haunting", "Introspective"],
        "description": "Psychosis is a haunting exploration of mental turmoil and the struggle to maintain sanity. The song's dark and brooding atmosphere, combined with introspective lyrics, creates a powerful depiction of inner conflict and psychological distress.",
        "youtube_link": "https://youtu.be/f0w_WYXPIMc?si=2Uj8eHP0XJxN8Lcm"
    },
    "Fragile": {
        "moods": ["Melancholic", "Vulnerable", "Tender"],
        "description": "Fragile is a tender and melancholic ballad that explores the vulnerability of the human heart. The lyrics touch on themes of emotional fragility and the pain of broken relationships, with a soft and gentle melody that underscores the song's emotional depth.",
        "youtube_link": "https://youtu.be/7TLO66aEiEs?si=1fK6-y0xtpGUh0R8"
    },
    "Clevermind": {
        "moods": ["Reflective", "Thought-Provoking", "Melancholic"],
        "description": "Clevermind is a thought-provoking song that reflects on the complexity of the human mind and the burden of overthinking. The reflective lyrics and melancholic tone invite listeners to ponder the intricacies of consciousness and self-awareness.",
        "youtube_link": "https://youtu.be/EVnzSyoDpoY?si=AY3KLXuNywpp-wsu"
    },
    "Miss Impossible": {
        "moods": ["Playful", "Energetic", "Quirky"],
        "description": "Miss Impossible is a playful and quirky track with an upbeat tempo. The song tells the story of an enigmatic and unpredictable character, with energetic rhythms and whimsical lyrics that add to its charm.",
        "youtube_link": "https://youtu.be/bncQ49Z4y5c?si=KQ4bpUJHz452HfPv"
    },
    "Diamonds for Tears": {
        "moods": ["Melancholic", "Reflective", "Nostalgic"],
        "description": "Diamonds for Tears is a melancholic song that deals with themes of loss and the fleeting nature of happiness. The reflective lyrics and nostalgic melody create a bittersweet atmosphere, capturing the emotional weight of saying goodbye.",
        "youtube_link": "https://youtu.be/t9Rl8WMqejc?si=Gw0-sGwe2SuJ-DBa"
    },
    "Passion Colors Everything": {
        "moods": ["Romantic", "Hopeful", "Uplifting"],
        "description": "Passion Colors Everything is a romantic and uplifting track that celebrates the transformative power of love and passion. The hopeful lyrics and warm melody make it a feel-good anthem about the beauty of life when seen through the lens of love.",
        "youtube_link": "https://youtu.be/N8ie35s4XJQ?si=BOpWXO7KogkZvkNK"
    },
    "Save Me": {
        "moods": ["Desperate", "Dark", "Intense"],
        "description": "Save Me is an intense and desperate cry for help, exploring themes of emotional turmoil and the desire for salvation. The dark lyrics and powerful melody convey a sense of urgency and inner struggle.",
        "youtube_link": "https://youtu.be/Yy-IkJddrX4?si=PmD4fExNMjmFK_ke"
    },
    "Where Do We Draw the Line": {
        "moods": ["Reflective", "Philosophical", "Melancholic"],
        "description": "Where Do We Draw the Line is a reflective and philosophical song that questions the boundaries of morality and the decisions we make in life. The melancholic tone and introspective lyrics prompt deep thought about the choices that define us.",
        "youtube_link": "https://youtu.be/tzSFIIe3Fxo?si=X-bTbMFoRodkbimG"
    }
}
export default revolution_roulette_album;