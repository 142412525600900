import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const MoodSelector = ({ moods, selectedMood, onMoodSelect }) => (
  <FormControl fullWidth>
    <InputLabel id="mood-select-label">Select your mood</InputLabel>
    <Select
      labelId="mood-select-label"
      id="mood-select"
      value={selectedMood}
      label="Select your mood"
      onChange={onMoodSelect}
    >
      {moods.map((mood) => (
        <MenuItem key={mood.id} value={mood.id}>{mood.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default MoodSelector;