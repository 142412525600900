const clearview_album = {
    "Drama for Life": {
        "moods": ["Energetic", "Intense", "Defiant"],
        "description": "Drama for Life is an energetic and intense track that deals with the struggles and battles within oneself. The defiant lyrics and powerful melody highlight the relentless pursuit of overcoming life's challenges.",
        "youtube_link": "https://youtu.be/xfvtPTvuFTA?si=GWSIya93HHj0Zneh"
    },
    "The Game": {
        "moods": ["Upbeat", "Reflective", "Cynical"],
        "description": "The Game is an upbeat yet reflective song that explores the metaphorical game of life. The lyrics provide a cynical take on the roles people play, accompanied by a catchy and rhythmic melody.",
        "youtube_link": "https://youtu.be/GCakyX8m_Zo?si=PqgTg6AQvhdKu_x0"
    },
    "The Child in Me": {
        "moods": ["Nostalgic", "Melancholic", "Hopeful"],
        "description": "The Child in Me is a nostalgic and melancholic track that reflects on the innocence and purity of childhood. The hopeful undertones and gentle melody evoke a longing for simpler times.",
        "youtube_link": "https://youtu.be/aBPcQOobiHM?si=CcZqJXedggvyOWax"
    },
    "Once Upon a Playground Rainy": {
        "moods": ["Reflective", "Melancholic", "Poignant"],
        "description": "Once Upon a Playground Rainy is a reflective and poignant song that captures the bittersweet memories of youth. The melancholic lyrics and soft melody convey a sense of lost innocence and the passage of time.",
        "youtube_link": "https://youtu.be/GAJY1DaaUc4?si=Yy8hO9ql18RbtUQx"
    },
    "Children of the Sun": {
        "moods": ["Uplifting", "Inspirational", "Hopeful"],
        "description": "Children of the Sun is an uplifting and inspirational anthem that speaks to the enduring spirit of humanity. The hopeful lyrics and soaring melody create a powerful sense of unity and optimism.",
        "youtube_link": "https://youtu.be/BTFHyRkmXbs?si=3yHWAue2aKSpHQCm"
    },
    "Shadow Play": {
        "moods": ["Mysterious", "Dark", "Intense"],
        "description": "Shadow Play is a mysterious and dark track that delves into the hidden aspects of the human psyche. The intense melody and enigmatic lyrics create an atmosphere of suspense and intrigue.",
        "youtube_link": "https://youtu.be/1bXpPkZjzfk?si=2VTm4UL-_xrgegK8"
    },
    "Center Stage": {
        "moods": ["Bold", "Empowering", "Energetic"],
        "description": "Center Stage is a bold and empowering song that encourages self-expression and stepping into the spotlight. The energetic rhythm and confident lyrics make it an anthem of self-assurance and individuality.",
        "youtube_link": "https://youtu.be/4rqGke_9lmk?si=6xDFwyb4LV5PFsFw"
    },
    "The Labyrinth": {
        "moods": ["Introspective", "Reflective", "Complex"],
        "description": "The Labyrinth is an introspective and complex track that explores the twists and turns of the mind and life. The reflective lyrics and intricate melody create a deep and contemplative atmosphere.",
        "youtube_link": "https://youtu.be/6LXokS6eYAQ?si=yv1z1DWiS25sh7ri"
    },
    "Crystalline": {
        "moods": ["Ethereal", "Dreamy", "Romantic"],
        "description": "Crystalline is an ethereal and dreamy song with a romantic undertone. The delicate melody and poetic lyrics evoke images of fragile beauty and a sense of transcendence.",
        "youtube_link": "https://youtu.be/2A1CmpixUms?si=t-Am6PGA6oHEi8hE"
    },
    "Moonlight Kissed": {
        "moods": ["Romantic", "Tender", "Nostalgic"],
        "description": "Moonlight Kissed is a romantic and tender ballad that captures the magic of love under the night sky. The nostalgic lyrics and gentle melody create an intimate and serene atmosphere.",
        "youtube_link": "https://youtu.be/rVZzQGRTT7c?si=yRhmzq2ub4KWZid4"
    }
}
export default clearview_album; 