const twilight_theater_album = {
    "Dreaming Wide Awake": {
        "moods": ["Uplifting", "Inspirational", "Hopeful"],
        "description": "Dreaming Wide Awake is an inspirational anthem about breaking free from limitations and embracing the boundless possibilities of life. The uplifting lyrics and soaring melody make it a song of hope and empowerment.",
        "youtube_link": "https://youtu.be/zC49_BA-eSM?si=wLZBVIkw1Z9oRLLi"
    },
    "War": {
        "moods": ["Intense", "Defiant", "Dark"],
        "description": "War is a powerful and intense track that explores the inner and outer conflicts that people face. The defiant lyrics and dark, brooding atmosphere reflect the struggles and battles that define human experience.",
        "youtube_link": "https://youtu.be/myzf6SLBBpY?si=9LThXnH4sumoYBYI"
    },
    "Change": {
        "moods": ["Reflective", "Hopeful", "Melancholic"],
        "description": "Change is a reflective song that deals with the inevitability of change and the emotional turmoil that comes with it. The melancholic yet hopeful tone captures the bittersweet nature of transformation and personal growth.",
        "youtube_link": "https://youtu.be/uqBxrDHHkE0?si=4nKUHubscUzd7qTo"
    },
    "15 Min Flame": {
        "moods": ["Energetic", "Playful", "Cynical"],
        "description": "15 Min Flame is a playful and energetic critique of fleeting fame and the superficiality of modern celebrity culture. The song's upbeat rhythm and cynical lyrics create a sharp commentary on society's obsession with fame.",
        "youtube_link": "https://youtu.be/tJk0T10o7vo?si=29ArCxKYT-mVukCd"
    },
    "Given and Denied": {
        "moods": ["Melancholic", "Nostalgic", "Romantic"],
        "description": "Given and Denied is a melancholic and romantic ballad that explores the pain of unfulfilled love. The nostalgic melody and heartfelt lyrics create a powerful sense of longing and regret.",
        "youtube_link": "https://youtu.be/0SZ8vmreVxw?si=HB3xXPFeOFpl5_Qb"
    },
    "Rewind": {
        "moods": ["Reflective", "Introspective", "Bittersweet"],
        "description": "Rewind is an introspective song about looking back on past mistakes and wishing to undo them. The reflective lyrics and bittersweet tone convey a deep sense of regret and the desire to make things right.",
        "youtube_link": "https://youtu.be/5OL_G1jS7gk?si=oAizistGuCLEMKiu"
    },
    "Dying to Live": {
        "moods": ["Philosophical", "Dark", "Intense"],
        "description": "Dying to Live is a philosophical exploration of the paradox between life and death. The dark and intense atmosphere, combined with thought-provoking lyrics, challenges listeners to reflect on the value of life in the face of mortality.",
        "youtube_link": "https://youtu.be/JQYZ6P9aRcg?si=0YIhrt6Xy9yHjNEB"
    },
    "You're Still Here": {
        "moods": ["Romantic", "Tender", "Nostalgic"],
        "description": "You're Still Here is a tender love song that expresses enduring affection and the lasting impact of a loved one, even in their absence. The romantic lyrics and nostalgic melody create a warm and heartfelt atmosphere.",
        "youtube_link": "https://youtu.be/vnDbz3iKuY0?si=N9o3vt4yYYK5EhwG"
    },
    "Smoke and Mirrors": {
        "moods": ["Mysterious", "Dark", "Brooding"],
        "description": "Smoke and Mirrors is a mysterious and dark track that delves into themes of deception and hidden truths. The brooding melody and enigmatic lyrics create a sense of intrigue and suspense.",
        "youtube_link": "https://youtu.be/XDOjQTZXC7g?si=_-OdlJ4eXIjXQ1Fn"
    },
    "Heal My Wounds": {
        "moods": ["Hopeful", "Healing", "Emotional"],
        "description": "Heal My Wounds is an emotional and hopeful song about recovery and the process of healing from pain. The soothing melody and uplifting lyrics convey a sense of resilience and the strength to overcome adversity.",
        "youtube_link": "https://youtu.be/yyZS9kuNcUE?si=ZZG-NdDEXKOHYhNG"
    }
}
export default twilight_theater_album;