const mood_song_map = {
    "melancholic": [
        {
            "title": "Sleep",
            "description": "A somber ballad exploring themes of loss and letting go",
            "video_link": "https://www.youtube.com/watch?v=OlshWg3isxQ"
        },
        {
            "title": "3 AM",
            "description": "A haunting melody about loneliness and late-night thoughts",
            "video_link": "https://www.youtube.com/watch?v=7FXTwWXAhds"
        },
        {
            "title": "Illusion and Dream",
            "description": "A melancholic journey through life's illusions and dreams",
            "video_link": "https://youtu.be/FqJqdp2xdk8?si=DG6Z4DmrWt3ZVGrI"
        },
        {
            "title": "Rebirth",
            "description": "A deeply emotional song about transformation and new beginnings",
            "video_link": "https://www.youtube.com/watch?v=v3_9TJt4bpU"
        },
        {
            "title": "Dying to Live",
            "description": "A poignant reflection on the struggle between life and death",
            "video_link": "https://www.youtube.com/watch?v=4XDCFjWGkuQ"
        },
        {
            "title": "Shallow",
            "description": "A melancholic exploration of superficiality and longing for depth",
            "video_link": "https://www.youtube.com/watch?v=bDz-4D7Xo6U"
        }
    ],
    "energetic": [
        {
            "title": "Carnival of Rust",
            "description": "An upbeat yet introspective song with a catchy chorus",
            "video_link": "https://www.youtube.com/watch?v=MKk1u5RMTn4"
        },
        {
            "title": "Daze",
            "description": "A high-energy rock song with a driving beat",
            "video_link": "https://www.youtube.com/watch?v=di7NMssrqsE"
        },
        {
            "title": "Lift",
            "description": "An energizing anthem about rising above challenges",
            "video_link": "https://www.youtube.com/watch?v=ompevuR1644"
        },
        {
            "title": "Dancing on Broken Glass",
            "description": "A lively tune with a catchy rhythm and powerful vocals",
            "video_link": "https://www.youtube.com/watch?v=GGTm_RZG8Ew"
        },
        {
            "title": "Center Stage",
            "description": "An energetic rock song about seizing the moment",
            "video_link": "https://youtu.be/4rqGke_9lmk?si=97CZRR5JnVPADnAA"
        },
        {
            "title": "Drama for Life",
            "description": "A dynamic and theatrical rock anthem",
            "video_link": "https://www.youtube.com/watch?v=s1g6Kga1h_U"
        }
    ],
    "introspective": [
        {
            "title": "War",
            "description": "A thought-provoking song about inner conflicts",
            "video_link": "https://www.youtube.com/watch?v=0f_hewSrAH4"
        },
        {
            "title": "Cradled in Love",
            "description": "A poignant reflection on love and loss",
            "video_link": "https://www.youtube.com/watch?v=aNO6yd66PpA"
        },
        {
            "title": "The Sweet Escape",
            "description": "A contemplative journey through memories and regrets",
            "video_link": "https://www.youtube.com/watch?v=xBG2u_oB5sQ"
        },
        {
            "title": "Someone Special",
            "description": "An introspective look at self-worth and personal growth",
            "video_link": "https://www.youtube.com/watch?v=MrQGNBh7Ja0"
        },
        {
            "title": "Overboard",
            "description": "A deep dive into the complexities of human emotions",
            "video_link": "https://www.youtube.com/watch?v=zTuDWSZETSI"
        },
        {
            "title": "Psychosis",
            "description": "An introspective exploration of the mind's darker corners",
            "video_link": "https://youtu.be/f0w_WYXPIMc?si=2Uj8eHP0XJxN8Lcm"
        }
    ],
    "empowering": [
        {
            "title": "Choice Millionaire",
            "description": "An inspiring song about taking control of one's destiny",
            "video_link": "https://www.youtube.com/watch?v=gDVe0HitNZo"
        },
        {
            "title": "Locking Up the Sun",
            "description": "A powerful anthem about overcoming darkness",
            "video_link": "https://www.youtube.com/watch?v=Pj47zW_lf5s"
        },
        {
            "title": "Diamonds for Tears",
            "description": "An uplifting song about finding strength in vulnerability",
            "video_link": "https://youtu.be/t9Rl8WMqejc?si=Gw0-sGwe2SuJ-DBa"
        },
        {
            "title": "The Ballad of Jeremiah Peacekeeper",
            "description": "An empowering narrative about standing up for what's right",
            "video_link": "https://www.youtube.com/watch?v=vQ39s5Ohl7I"
        },
        {
            "title": "Revolution Roulette",
            "description": "A call to action against societal complacency",
            "video_link": "https://www.youtube.com/watch?v=C-KWbDzyU-w"
        },
        {
            "title": "Passion Colors Everything",
            "description": "An empowering song about living life with passion",
            "video_link": "https://www.youtube.com/watch?v=rW9dwvBXLec"
        }
    ],
    "romantic": [
        {
            "title": "Stay",
            "description": "A tender love song with heartfelt lyrics",
            "video_link": "https://www.youtube.com/watch?v=diTh8S4P6m0"
        },
        {
            "title": "Desire",
            "description": "A passionate exploration of romantic longing",
            "video_link": "https://www.youtube.com/watch?v=A1vdGmkwFR0"
        },
        {
            "title": "Moonlight Kissed",
            "description": "A dreamy, romantic melody about love's enchantment",
            "video_link": "https://www.youtube.com/watch?v=yt55bFaJ36k"
        },
        {
            "title": "In a Perfect World",
            "description": "A hopeful love song about finding perfection in imperfection",
            "video_link": "https://www.youtube.com/watch?v=dJ6XaKSy-iQ"
        }
    ],
    "mysterious": [
        {
            "title": "Temple of Thought",
            "description": "A haunting song with enigmatic lyrics",
            "video_link": "https://www.youtube.com/watch?v=NfLUcaQ_jzM"
        },
        {
            "title": "Crystals",
            "description": "A mesmerizing song with a mystical atmosphere",
            "video_link": "https://www.youtube.com/watch?v=P0XjLmuC7oM"
        },
        {
            "title": "Rogue",
            "description": "An intriguing song with a sense of hidden depths",
            "video_link": "https://www.youtube.com/watch?v=3Bt-E8IBVfw"
        },
        {
            "title": "The Labyrinth",
            "description": "A complex, multi-layered song with cryptic lyrics",
            "video_link": "https://www.youtube.com/watch?v=miBKIjTh17Q"
        },
        {
            "title": "Ghostlight",
            "description": "A haunting melody with an air of supernatural mystery",
            "video_link": "https://www.youtube.com/watch?v=SrGCdjLNYoc"
        },
        {
            "title": "False Kings",
            "description": "An enigmatic song about deception and hidden truths",
            "video_link": "https://www.youtube.com/watch?v=0UF9YgUvBn0"
        }
    ],
    "nostalgic": [
        {
            "title": "Dreaming Wide Awake",
            "description": "A reflective song about past dreams and present realities",
            "video_link": "https://www.youtube.com/watch?v=f2-HOaIO7Wg"
        },
        {
            "title": "Twilight Theater",
            "description": "A nostalgic journey through life's stages",
            "video_link": "https://www.youtube.com/watch?v=YOhUHSMNHsI"
        },
        {
            "title": "Moments Before the Storm",
            "description": "A nostalgic look at pivotal moments in life",
            "video_link": "https://www.youtube.com/watch?v=pZpEWX0zWgU"
        }
    ],
    "hopeful": [
        {
            "title": "Standstill",
            "description": "An uplifting song about perseverance and hope",
            "video_link": "https://www.youtube.com/watch?v=0H85LV4uRg8"
        },
        {
            "title": "Choir of Cicadas",
            "description": "A peaceful, hopeful melody about finding serenity",
            "video_link": "https://www.youtube.com/watch?v=HrNVHt20zYc"
        },
        {
            "title": "Heal My Wounds",
            "description": "A song of healing and moving forward",
            "video_link": "https://www.youtube.com/watch?v=5eNGS8oxaSA"
        },
        {
            "title": "The Lie Eternal",
            "description": "A song about finding truth and hope in a world of illusions",
            "video_link": "https://www.youtube.com/watch?v=a8rnNAOlQDY"
        },
        {
            "title": "Change",
            "description": "An inspiring song about personal growth and transformation",
            "video_link": "https://www.youtube.com/watch?v=7sGZshENJ0M"
        },
        {
            "title": "Beyond the Horizon",
            "description": "A hopeful song about new beginnings and possibilities",
            "video_link": "https://youtu.be/y0-t9yntElA?si=bP3J8ixPaqcW9E7C"
        }
    ],
    "bittersweet": [
        {
            "title": "Jealous Gods",
            "description": "A bittersweet exploration of human nature and relationships",
            "video_link": "https://www.youtube.com/watch?v=9rQHrG_CJqM"
        },
        {
            "title": "Given and Denied",
            "description": "A poignant song about the complexities of love and loss",
            "video_link": "https://www.youtube.com/watch?v=DfGVa-fZ7ng"
        },
        {
            "title": "My Dark Disquiet",
            "description": "A hauntingly beautiful song about inner struggles and hope",
            "video_link": "https://www.youtube.com/watch?v=a_CpE7R_NuA"
        },
        {
            "title": "Skin",
            "description": "A bittersweet reflection on vulnerability and human connections",
            "video_link": "https://www.youtube.com/watch?v=4FkFh4j53J0"
        },
    ],
    "happy": [
        {
            "title": "Kamikaze Love",
            "description": "An upbeat, feel-good song about reckless love",
            "video_link": "https://www.youtube.com/watch?v=rVlZCXHL9es"
        },
        {
            "title": "Brighter Than the Sun",
            "description": "A joyful, energetic song celebrating life and love",
            "video_link": "https://www.youtube.com/watch?v=X3XbJa6GWhs"
        },
        {
            "title": "Can You Hear Me",
            "description": "A positive, uplifting song about connection and joy",
            "video_link": "https://youtu.be/I2PmwSgkHUI?si=GWDpi4Lcka_DkWci"
        },
        {
            "title": "Carnival of Rust",
            "description": "A more upbeat, cheerful version of their hit song",
            "video_link": "https://www.youtube.com/watch?v=m-s_5j5H5NI"
        }
    ]
}

export default mood_song_map;