import React from 'react';
import { Box } from '@mui/material';

const VideoPlayer = ({ videoId }) => (
  <Box sx={{ width: '100%', height: { xs: '200px', md: '300px' } }}>
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{ width: '100%', height: '100%' }}
    />
  </Box>
);

export default VideoPlayer;